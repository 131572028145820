import { PageProps } from 'gatsby';
import { CollectionData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/collection.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceCollectionUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, collectionId },
}) => {
  const model = new CollectionData({
    espaceId,
    params,
  });

  return (
    <TemplateEspace
      collectionName="collections"
      docId={collectionId}
      espace={espace}
      user={user}
    >
      <CMSView>
        <Form
          docId={collectionId}
          itemPathnamePrefix={`/espaces/${espaceId}/collections/`}
          model={model}
          name="collection"
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceCollectionUpdate);
